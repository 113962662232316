<template>
  <div class="supply">
    <div class="headBody">
      <img class="headBg" v-if="cur == 0"  src="../../assets/img/supply1.jpg"/>
      <img class="headBg" v-if="cur == 1"  src="../../assets/img/supply2.jpg"/>
      <img class="headBg" v-if="cur == 2"  src="../../assets/img/supply3.jpg"/>
      <div class="headBodyTitle" :class="isHovered?'titleBag':''">
        <div class="headIemt">
          <div  class="textSpan" @click="handleSelect">
            <img  style="width:113px;height:29px"  src="../../assets/img/logo0.png"/></div>
          <div class="textSpan">
            <div class="tabItem"
                 @mouseover="handleMouseOver"
                 @mouseleave="handleMouseLeave"
                 @click="cur = index"
                 v-for="(item, index) in tabs" :key="index" :class="cur == index ? 'divHover':''">
              {{item}}
              <span  class="bottomBor"></span>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex;flex-direction: column;align-items: center;justify-content: center">
        <div  v-if="cur == 0" class="titleBody">利息低，额度高，审批快</div>
        <div  v-if="cur == 1" class="titleBody">用数字化让企业焕发活力</div>
        <div  v-if="cur == 2" class="titleBody">快速响应  安全无优</div>
        <div class="btn" @click="btnClick">
          咨询服务
        </div>
      </div>
    </div>
    <div class="textBody">
      <div class="textBody1" v-if="cur == 0" >
        <div class="topText" style="padding-bottom:50px">
          <div>
            <div class="styleText">什么是代理采购？</div>
            <div class="styleText2">至刚网平台上的供应链服务公司为有采购需求的合作伙伴垫付采购货款的一种供应链服务。</div>
          </div>
          <div style="width:535px;height:305px;margin-left:100px">
            <img src="../../assets/img/supplyImg3.png" style="width:100%;height:100%"/>
          </div>
        </div>
        <div class="bottomText" style="padding-top:50px;border-top: 1px dashed #B1B1B1;">
          <div class="styleText1">联系方式：</div>
          <div class="styleText">（江苏）魏总：17366092896 <span style="margin-left:100px">（上海）柴总：13501889383</span></div>
          <div class="styleText">（江苏）华总：13770671025 </div>
        </div>
      </div>
      <div class="textBody1" v-if="cur == 1" >
          <div class="topText" >
            <div>
              <div class="styleText">数字化服务简介？</div>
              <div class="styleText2">基于至刚网多年的供应链行业经验，与湖南链动云科技有限公司紧密合作，为供应链领域的企业，提供在线交易、供应链业务与管理数字化、企业财费数字化等系列场景数字化解决方案及数字产品</div>
            </div>
            <div style="width:356px;height:388px;margin-left:100px">
              <img src="../../assets/img/supplyImg1.jpg" style="width:100%;height:100%"/>
            </div>
          </div>
          <div class="bottomText">
            <div style="margin:27px auto">数字化服务体系</div>
            <div style="width:1200px;height:627px;">
              <img src="../../assets/img/supplyImg2.png" style="width:100%;height:100%"/>
            </div>
          </div>
      </div>
      <div class="textBody1" v-if="cur == 2" >
        <div class="topText" >
          <div>
            <div class="styleText">物流服务介绍</div>
            <div class="styleText2">
              <div>上海仟众科技有限公司是至刚网的运输货物运营商。</div>
              上海仟众科技有限公司是一家以物联网为基础的物流产业链平台,总部设立在上海,在西南·西北、华北东北、皖苏鲁等区域均设立子公司和分支机构。目前有货主企业会员3000+，运输企业会员6500+，年营收30亿元+，年订单数60万+，货物年运输量4800万吨,覆盖31个省区,450个城市,10730+市级线路公司以构建智慧物流生态圈为理念,积极响应国家的政策与创新模式,以“仟众智慧,科技未来”的企业口号帮助企业打造属于自己的智慧物流。</div>
          </div>
          <div style="width:535px;height:305px;margin-left:100px">
            <img src="../../assets/img/supplyImg4.png" style="width:100%;height:100%"/>
          </div>
        </div>
        <div class="bottomText">
          <div style="margin:27px auto">服务优势</div>
          <div style="width:1200px;height:680px;">
            <img src="../../assets/img/supplyImg5.png" style="width:100%;height:100%"/>
          </div>
        </div>
      </div>
    </div>
    <footerNav />
    <el-dialog
        title="咨询服务"
        :visible.sync="dialogVisible"
        width="502px"
        :before-close="handleClose">
          <div style="display:flex;align-items:center;justify-content:center;margin:20px auto;">
            <img style="width:36px;height:36px;margin-right:9px;" src="../../assets/img/phone2.png"/>
            <span class="dialogText">咨询热线：186-0731-3947</span>
          </div>
    </el-dialog>
  </div>
</template>

<script>
import footerNav from "../../components/footerNav.vue";
	export default {
		name: "supply",
		components: {
      footerNav
		},
    props: {
    },
		data() {
			return {
				tabs: ['金融服务', '数字化服务', '物流服务'],
        cur:0,
        isHovered:false,
        dialogVisible:false,
			};
		},
		mounted() {
      if(this.$route.query?.cur){
        this.cur = this.$route.query?.cur;
      }
		},
		methods: {
      handleSelect(item) {
        this.$router.push({
          name: "home",
        });
      },
      handleMouseOver() {
        this.isHovered = true;
      },
      handleMouseLeave() {
        this.isHovered = false;
      },
      btnClick(){
        this.dialogVisible = true
       },
      handleClose(){
        this.dialogVisible = false
      },
    },
	};
</script>
<style>
.el-dialog__title{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #000102;
  line-height: 33px;
  text-align: left;
  font-style: normal;
}
</style>
 <style lang="scss" scoped>
  .dialogText{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #000102;
    line-height: 25px;
    text-align: left;
    font-style: normal
  }
  .supply {
    min-width: 100vw;
    min-height: 100vh;
    text-align: center;
  }
  .supply  .headBody{
    width: 100%;
    height: 648px;
   }
  .supply  .headBody .headBg{
     position: absolute;
     top: 0;
     left: 0;
     z-index: -1;
     width: 100%;
     height: 648px;
  }
  .textBody{
    width: 1200px;
    margin: 50px auto;
  }
  .textBody1 .topText{
    display: flex;
    text-align: left;
   }
  .textBody1 .topText .styleText{
    width: 420px;
    display: inline-block;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #000012;
    line-height: 33px;
    text-align: left;
    font-style: normal;
    padding-bottom: 32px;
    border-bottom: 2px solid #000012;



  }
  .textBody1 .topText .styleText2{
    width: 650px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #000012;
    line-height: 30px;
    text-align: left;
    font-style: normal;
    padding-top: 32px;
  }
  .textBody1  .bottomText{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000012;
    line-height: 25px;
    text-align: left;
    font-style: normal;
  }
  .textBody1  .bottomText .styleText1{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000012;
    line-height: 25px;
    text-align: left;
    font-style: normal;
    padding-bottom: 32px;
  }
  .textBody1  .bottomText .styleText{
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #202D37;
    line-height: 21px;
    text-align: left;
    font-style: normal;
    padding-bottom: 32px;
  }

 .supply .headBodyTitle{
    width: 100%;
    height: 70px;
    transition: background-color 0.8s ;
  }
 .supply .titleBag{
    background-color: #000012; /* 默认背景色 */
  }
  .supply .headIemt{
    width: 1200px;
    height:70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0 auto;
  }
 .supply .textSpan{
    min-width: 120px;
    height:70px;
    display: flex;
    align-items: center;
    cursor:pointer;

  }
  .supply .textSpan .tabItem{
    width: 92px;
    height: 70px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 70px;
    text-align: center;
    font-style: normal;
    user-select: none;
    cursor:pointer;
    margin:0px 30px;
  }
  .supply .textSpan .bottomBor{
     display: none;
  }
  .supply .textSpan .tabItem:hover{
    height: 70px;
    color: #137EFF;
    font-weight: 600;
  }
   .divHover{
    height: 70px!important;
    color: #137EFF!important;
    font-weight: 600!important;
  }
  .supply  .textSpan .tabItem:hover .bottomBor{
    display: flex;
    width: 100%;
    height: 13px;
    position:relative;
    top:-13px;
    background:url('../../assets/img/bottom.png') no-repeat center;
    background-size: 100% 100%;
  }
  .supply  .titleBody{
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 48px;
    color: #FFFFFF;
    line-height: 67px;
    text-align: left;
    font-style: normal;
    margin-top: 160px;
  }
  .supply  .btn{
    width: 175px;
    height: 50px;
    background: #F67F3E;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    font-style: normal;
    margin: 20px auto;
    cursor:pointer;
    user-select: none;
  }
  .supply  .btn:hover{
    background: #D46223;
  }
</style>