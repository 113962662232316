<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  底部栏目
-->
<template>
  <div class="footer">
      <div class="footer-nav-text">
        <div class="Homefootbody">
          <div class="title1">
           <div @click="handleSelect(1)">首页</div>
            <span>｜</span>
            <div @click="handleSelect(2)">现货资源</div>
            <span>｜</span>
            <div @click="handleSelect(3)">供应链服务</div>
            <span>｜</span>
            <div @click="handleSelect(4)">关于我们</div>
          </div>
          <div  class="title2">至刚网<span></span>版权所有 Copyright©至刚网2014-2021<span></span><a target="_blank" href="http://beian.miit.gov.cn" style="color: #FFFFFF;">浙ICP备2024116149号-1</a></div>
          <div  class="title2">咨询热线:18607313947<span></span>不良和违法信息举报电话:0731-81801099 （5*8工作日接听）</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "footerNav",
  data() {
    return {};
  },
  methods: {
    handleSelect(item) {
      if (item == 1) {
        this.$router.push({
          name: "home",
        });
      } else if (item == 2) {
        this.$router.push({
          name: "spot",
        });
      } else if (item == 3) {
        let routeData = this.$router.resolve({
          path: '/supply/index'
        });
        window.open(routeData.href, '_blank');
      } else if (item == 4) {
        this.$router.push({
          name: "aboutUs",
        });
      }
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.footer {
  width: 100%;
  height: 184px;
  background-color: #232323;
}

.Homefootbody{
  width: 1200px;
  height: 184px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title1{
  width: 346px;
  height: 17px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  margin: 8px auto;
  div{
    display:inline-block;
    cursor:pointer;
  }
  div:hover{
    color: #137EFF;
  }
  span{
    padding:0px 15px
  }
}
.title2{
  height: 17px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  opacity: 0.7;
  margin-top: 12px;
  span{
    padding:0px 10px
  }
}


</style>
